import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { MdPinDrop, MdPhone, MdPhoneIphone, MdEmail } from 'react-icons/md';
import Helmet from '../../config/helmet';
import GoogleMaps from '../components/GoogleMaps';
import Container from '../components/UI/Container';

const { Title } = Typography;

const Wrapper = styled(Container)`
  padding-top: 100px !important;
  padding-bottom: 2rem;
  height: calc(100vh - 208.17px);

  @media (max-width: 992px) {
    height: 100%;
  }
`;

const Content = styled.div`
  li {
    p {
      display: inline;
      word-break: normal;
    }

    svg {
      height: auto;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  @media (min-width: 992.98px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1.5rem;
  }
`;

export default () => (
  <Wrapper>
    <Helmet title='Contact Us' />

    <Title>Contact Us</Title>
    <Content>
      <div>
        <Title level={4}>
          We are here to answer any inquiry you may have about Jorisan Waterfront Resort. Reach out to us and we will
          respond as soon as we can.
        </Title>
        <ul>
          <li>
            <MdPinDrop />
            <p>Pagdalagan Sur, Bauang, La Union, Philippines</p>
          </li>
          <li>
            <MdPhone />
            <p>(+072) 682-9609</p>
          </li>
          <li>
            <MdPhoneIphone />
            <p>(+63) 917-8365-925</p>
          </li>
          <li>
            <MdEmail />
            <p>jorisan.wfresort@yahoo.com</p>
          </li>
        </ul>
      </div>
      <GoogleMaps isMarkerShown />
    </Content>
  </Wrapper>
);
